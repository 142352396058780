export const colors = {
    primary: "#7187d6",
    green: "#3bb483",
    homeIcon: "#fff",
    addGuildBorder: "#535559",

    grayLight: "#36393f",
    grayNormal: "#2f3136",
    grayDark: "#2e3034",
    grayDarker: "#202225",
    separator: "#2f3136",

    channelName: "#72767d",
    channelSelected: "rgba(79, 84, 92, .6)",
    channelHoveredText: "#b9bbbe",

    privateChannelSelectedBackground: "rgba(79, 84, 92, .6)",

    channelsUserFooterBackground: "rgba(32, 34, 37, .3)",

    icon: "#7d7f84",
    iconHover: "#fff",

    memberHoverBg: "#36393f",
    memberUsernameOnline: "#b9bbbe",
    memberStatus: "rgba(185, 187, 190, .6)",
    memberUsernameChat: "#fff",

    welcomeChannelMessage: "#949494",
    messageContent: "#dcddde",
    messageTime: "hsla(0, 0%, 100%, .2)",
    messageDivider: "hsla(0,0%,100%,.04)",

    scrollbarThumbBackground: "#202225",
    scrollbarTrackBackground: "rgba(0, 0, 0, 0.13)",
    scrollbarTinyThumbBackground: "rgba(32, 34, 37, 0.6)",

    memberCardHeaderBackground: "#202225",
    memberCardHeaderPlayingBackground: "#7289da",
    memberCardBackground: "#2f3136",
    memberCardContent: "hsla(0,0%,100%,.8)",
    memberCardUserTag: "hsla(0, 0%, 100%, 0.6)",
    memberCardFieldKey: "#72767d",
    memberCardNoteInput: "#b9bbbe",
    memberCardFooterBorder: "rgba(32, 34, 37, .3)",

    proptipLabel: "#43b581",
    proptipText: "rgba(255, 255, 255, 0.5)"
};
