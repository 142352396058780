import { Container, Row, Col } from "react-bootstrap";

export const Support = () => {
    return (
        <Container>
            <Row className="text-center pt-4">
                <Col>Nada por aquí</Col>
            </Row>
        </Container>
    );
};
